@use '@angular/material' as mat;
@include mat.core();
@import "./propup/propup.theme";
@import "./remax/remax.theme";
@import "./sulek/sulek.theme";
@import "./wohncloud/wohncloud.theme";
@import "./grasl/grasl.theme";
@import "./4immobilien/4immobilien.theme";
@import "./immobilien-melk/immobilien-melk.theme";
@import "./altera/altera.theme";
@import "./finova/finova.theme";
@import "./hagsteiner/hagsteiner.theme";
@import "./qualis/qualis.theme";
@import "./edelweiss/edelweiss.theme";
@import "./remax-collection/remax-collection.theme";
@import "./remax-classic/remax-classic.theme";
@import "./prosch/prosch.theme";
@import "./ahImmobilien/ahImmobilien.theme";
@import "./aaaaImmobilien/aaaaImmobilien.theme";
@import "./norisk/norisk.theme";
@import "./mayrImmo/mayrImmo.theme";
@import "./eraImmo/eraImmo.theme";
@import "./sonnberger/sonnberger.theme";
@import "./virimo/virimo.theme";
@import "./froeschl/froeschl.theme";
@import "./realwert/realwert.theme";
@import "./strigl/strigl.theme";
@import "./immobilienring/immobilienring.theme";


$propup-primary: mat.m2-define-palette($md-primary, 500);
$propup-accent: mat.m2-define-palette($pro-secondary, 500);
$propup-warn: mat.m2-define-palette($pro-warn, 500);

$propup-theme: mat.m2-define-light-theme((color: (primary: $propup-primary, accent: $propup-accent, warn: $propup-warn),
      typography: mat.m2-define-typography-config(),
      density: 0,
    ));

@include mat.all-component-themes($propup-theme);

// RE/MAX Theme
.theme-remax {
  $remax-primary: mat.m2-define-palette($remax-primary, 700);
  $remax-accent: mat.m2-define-palette($remax-secondary, 800);
  $remax-warn: mat.m2-define-palette($remax-warning, 700);

  $remax-theme: mat.m2-define-light-theme((color: (primary: $remax-primary, accent: $remax-accent, warn: $remax-warn),
      typography: mat.m2-define-typography-config(),
      density: 0,
    ));

  @include mat.all-component-colors($remax-theme);
}

// Sulek Theme
.theme-sulek {
  $sulek-primary: mat.m2-define-palette($theme-primary, 700);
  $sulek-accent: mat.m2-define-palette($theme-secondary, 800);
  $sulek-warn: mat.m2-define-palette($theme-warn, 700);

  $sulek-theme: mat.m2-define-light-theme((color: (primary: $sulek-primary, accent: $sulek-accent, warn: $sulek-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($sulek-theme);
}

// WohnCloud Theme
.theme-wohncloud {
  $wohncloud-primary: mat.m2-define-palette($wc-primary, 700);
  $wohncloud-accent: mat.m2-define-palette($wc-secondary, 800);
  $wohncloud-warn: mat.m2-define-palette($wc-warn, 700);

  $wohncloud-theme: mat.m2-define-light-theme((color: (primary: $wohncloud-primary, accent: $wohncloud-accent, warn: $wohncloud-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($wohncloud-theme);
}

// Froeschl Theme
.theme-froeschl {
  $froeschl-primary: mat.m2-define-palette($froeschl-primary, 900);
  $froeschl-accent: mat.m2-define-palette($froeschl-secondary, 900);
  $froeschl-warn: mat.m2-define-palette($froeschl-warn, 700);

  $froeschl-theme: mat.m2-define-light-theme((color: (primary: $froeschl-primary, accent: $froeschl-accent, warn: $froeschl-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($froeschl-theme);
}

// Grasl Theme
.theme-grasl {
  $grasl-primary: mat.m2-define-palette($grasl-primary, 500);
  $grasl-accent: mat.m2-define-palette($grasl-secondary, 500);
  $grasl-warn: mat.m2-define-palette($grasl-warn, 700);

  $grasl-theme: mat.m2-define-light-theme((color: (primary: $grasl-primary, accent: $grasl-accent, warn: $grasl-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($grasl-theme);
}

  // 4Immo Theme
  .theme-immo4 {
    $immo4-primary: mat.m2-define-palette($immo4-primary, 500);
    $immo4-accent: mat.m2-define-palette($immo4-secondary, 500);
    $immo4-warn: mat.m2-define-palette($immo4-warn, 700);

    $immo4-theme: mat.m2-define-light-theme((color: (primary: $immo4-primary, accent: $immo4-accent, warn: $immo4-warn),
            typography: mat.m2-define-typography-config(),
            density: 0,
    ));

  @include mat.all-component-colors($immo4-theme);
}

// Immobilien Melk Theme
.theme-immobilienMelk {
  $immobilienMelk-primary: mat.m2-define-palette($immobilienMelk-primary, 500);
  $immobilienMelk-accent: mat.m2-define-palette($immobilienMelk-secondary, 500);
  $immobilienMelk-warn: mat.m2-define-palette($immobilienMelk-warn, 700);

  $immobilienMelk-theme: mat.m2-define-light-theme((color: (primary: $immobilienMelk-primary, accent: $immobilienMelk-accent, warn: $immobilienMelk-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($immobilienMelk-theme);
}

// Qualis Theme
.theme-qualis {
  $qualis-primary: mat.m2-define-palette($qualis-primary, 500);
  $qualis-accent: mat.m2-define-palette($qualis-secondary, 500);
  $qualis-warn: mat.m2-define-palette($qualis-warn, 700);

  $qualis-theme: mat.m2-define-light-theme((color: (primary: $qualis-primary, accent: $qualis-accent, warn: $qualis-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($qualis-theme);
}

// Altera Theme
.theme-altera {
  $altera-primary: mat.m2-define-palette($altera-primary, 500);
  $altera-accent: mat.m2-define-palette($altera-secondary, 500);
  $altera-warn: mat.m2-define-palette($altera-warn, 700);

  $altera-theme: mat.m2-define-light-theme((color: (primary: $altera-primary, accent: $altera-accent, warn: $altera-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($altera-theme);
}

// Finova Theme
.theme-finova {
  $finova-primary: mat.m2-define-palette($finova-primary, 500);
  $finova-accent: mat.m2-define-palette($finova-secondary, 500);
  $finova-warn: mat.m2-define-palette($finova-warn, 700);

  $finova-theme: mat.m2-define-light-theme((color: (primary: $finova-primary, accent: $finova-accent, warn: $finova-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($finova-theme);
}

// Hagsteiner Theme
.theme-hagsteiner {
  $hagsteiner-primary: mat.m2-define-palette($hagsteiner-primary, 500);
  $hagsteiner-accent: mat.m2-define-palette($hagsteiner-secondary, 500);
  $hagsteiner-warn: mat.m2-define-palette($hagsteiner-warn, 700);

  $hagsteiner-theme: mat.m2-define-light-theme((color: (primary: $hagsteiner-primary, accent: $hagsteiner-accent, warn: $hagsteiner-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));
  @include mat.all-component-colors($hagsteiner-theme);
}

  //Edelweiss Theme
  .theme-edelweiss {
    $edelweiss-primary: mat.m2-define-palette($edelweiss-primary, 500);
    $edelweiss-accent: mat.m2-define-palette($edelweiss-secondary, 500);
    $edelweiss-warn: mat.m2-define-palette($edelweiss-warn, 700);

    $edelweiss-theme: mat.m2-define-light-theme((color: (primary: $edelweiss-primary, accent: $edelweiss-accent, warn: $edelweiss-warn),
            typography: mat.m2-define-typography-config(),
            density: 0,
    ));

  @include mat.all-component-colors($edelweiss-theme);

}

// RE/MAX Collection Theme
.theme-remaxCollection {
  $remaxCollection-primary: mat.m2-define-palette($remaxCollection-primary, 500);
  $remaxCollection-accent: mat.m2-define-palette($remaxCollection-secondary, 800);
  $remaxCollection-warn: mat.m2-define-palette($remaxCollection-warning, 700);

  $remaxCollection-theme: mat.m2-define-light-theme((color: (primary: $remaxCollection-primary, accent: $remaxCollection-accent, warn: $remaxCollection-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($remaxCollection-theme);
}

// RE/MAX Classic Theme
.theme-remaxClassic {
  $remaxClassic-primary: mat.m2-define-palette($remaxClassic-primary, 700);
  $remaxClassic-accent: mat.m2-define-palette($remaxClassic-secondary, 800);
  $remaxClassic-warn: mat.m2-define-palette($remaxClassic-warning, 700);

  $remaxClassic-theme: mat.m2-define-light-theme((color: (primary: $remaxClassic-primary, accent: $remaxClassic-accent, warn: $remaxClassic-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($remaxClassic-theme);
}

// AH Immobilien Theme
.theme-ahImmobilien {
  $ahImmobilien-primary: mat.m2-define-palette($ahImmobilien-primary, 500);
  $ahImmobilien-accent: mat.m2-define-palette($ahImmobilien-secondary, 500);
  $ahImmobilien-warn: mat.m2-define-palette($ahImmobilien-warn, 700);

  $ahImmobilien-theme: mat.m2-define-light-theme((color: (primary: $ahImmobilien-primary, accent: $ahImmobilien-accent, warn: $ahImmobilien-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($ahImmobilien-theme);
}

// Prosch Theme
.theme-prosch {
  $prosch-primary: mat.m2-define-palette($prosch-primary, 500);
  $prosch-accent: mat.m2-define-palette($prosch-secondary, 500);
  $prosch-warn: mat.m2-define-palette($prosch-warn, 700);

  $prosch-theme: mat.m2-define-light-theme((color: (primary: $prosch-primary, accent: $prosch-accent, warn: $prosch-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($prosch-theme);
}

// AAAA Immobilien Theme
.theme-aaaaImmobilien {
  $aaaaImmobilien-primary: mat.m2-define-palette($aaaaImmobilien-primary, 500);
  $aaaaImmobilien-accent: mat.m2-define-palette($aaaaImmobilien-secondary, 500);
  $aaaaImmobilien-warn: mat.m2-define-palette($aaaaImmobilien-warn, 700);

  $aaaaImmobilien-theme: mat.m2-define-light-theme((color: (primary: $aaaaImmobilien-primary, accent: $aaaaImmobilien-accent, warn: $aaaaImmobilien-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($aaaaImmobilien-theme);
}

// No Risk Theme
.theme-norisk {
  $norisk-primary: mat.m2-define-palette($norisk-primary, 500);
  $norisk-accent: mat.m2-define-palette($norisk-secondary, 500);
  $norisk-warn: mat.m2-define-palette($norisk-warn, 700);

  $norisk-theme: mat.m2-define-light-theme((color: (primary: $norisk-primary, accent: $norisk-accent, warn: $norisk-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($norisk-theme);
}

// MayrImmo Theme
.theme-mayrImmo {
  $mayrImmo-primary: mat.m2-define-palette($mayrImmo-primary, 500);
  $mayrImmo-accent: mat.m2-define-palette($mayrImmo-secondary, 500);
  $mayrImmo-warn: mat.m2-define-palette($mayrImmo-warn, 700);

  $mayrImmo-theme: mat.m2-define-light-theme((color: (primary: $mayrImmo-primary, accent: $mayrImmo-accent, warn: $mayrImmo-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($mayrImmo-theme);
}

// EraImmo Theme
.theme-eraImmo {
  $eraImmo-primary: mat.m2-define-palette($eraImmo-primary, 500);
  $eraImmo-accent: mat.m2-define-palette($eraImmo-secondary, 500);
  $eraImmo-warn: mat.m2-define-palette($eraImmo-warn, 700);

  $eraImmo-theme: mat.m2-define-light-theme((color: (primary: $eraImmo-primary, accent: $eraImmo-accent, warn: $eraImmo-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($eraImmo-theme);
}

// Sonnberger Theme
.theme-sonnberger {
  $sonnberger-primary: mat.m2-define-palette($sonnberger-primary, 500);
  $sonnberger-accent: mat.m2-define-palette($sonnberger-secondary, 500);
  $sonnberger-warn: mat.m2-define-palette($sonnberger-warn, 700);

  $sonnberger-theme: mat.m2-define-light-theme((color: (primary: $sonnberger-primary, accent: $sonnberger-accent, warn: $sonnberger-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($sonnberger-theme);
}

// Virimo Theme
.theme-virimo {
  $virimo-primary: mat.m2-define-palette($virimo-primary, 500);
  $virimo-accent: mat.m2-define-palette($virimo-secondary, 500);
  $virimo-warn: mat.m2-define-palette($virimo-warn, 700);

  $virimo-theme: mat.m2-define-light-theme((color: (primary: $virimo-primary, accent: $virimo-accent, warn: $virimo-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($virimo-theme);
}

// Realwert Theme
.theme-realwert {
  $realwert-primary: mat.m2-define-palette($realwert-primary, 500);
  $realwert-accent: mat.m2-define-palette($realwert-secondary, 500);
  $realwert-warn: mat.m2-define-palette($realwert-warn, 700);

  $realwert-theme: mat.m2-define-light-theme((color: (primary: $realwert-primary, accent: $realwert-accent, warn: $realwert-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($realwert-theme);
}

// Strigl Theme
.theme-strigl {
  $strigl-primary: mat.m2-define-palette($strigl-primary, 500);
  $strigl-accent: mat.m2-define-palette($strigl-secondary, 500);
  $strigl-warn: mat.m2-define-palette($strigl-warn, 700);

  $strigl-theme: mat.m2-define-light-theme((color: (primary: $strigl-primary, accent: $strigl-accent, warn: $strigl-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($strigl-theme);
}

// ImmobilienRing Theme
.theme-immobilienring {
  $immobilienring-primary: mat.m2-define-palette($immobilienring-primary, 500);
  $immobilienring-accent: mat.m2-define-palette($immobilienring-secondary, 500);
  $immobilienring-warn: mat.m2-define-palette($immobilienring-warn, 700);

  $immobilienring-theme: mat.m2-define-light-theme((color: (primary: $immobilienring-primary, accent: $immobilienring-accent, warn: $immobilienring-warn),
          typography: mat.m2-define-typography-config(),
          density: 0,
  ));

  @include mat.all-component-colors($immobilienring-theme);
}